<template>
<v-expansion-panel>
    <delete-warning :confirm-delete="deleteUserSkills" :on-close="onDeleteUserSkillsClose" :show-delete="showUserSkillsDelete" :loading="loadingUserSkills" />
    <v-expansion-panel-header>
        {{ $t("skills") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
        <v-row>
            <v-col col="12" class="d-flex justify-end">
                <v-btn @click="openSkillsDialog" color="bg-green-prosk" small class="white--text">
                    {{ !userData.skills || userData.skills.length > 0 ? $t("editSkill") : $t("AddSkillToUser") }}
                </v-btn>
            </v-col>
        </v-row>
        <AddSkillsToUserAdminDialog :key="showSkillsDialog" :showDialog="showSkillsDialog" :userId="userData.id" :onClose="closeSkillsDialog" :refreshDataFn="fetchUser" :userSkillsIds="userData.skills ? userData.skills.map(v=>v.id): []" />
        <v-chip-group column v-show="isLoading === false &&  this.userData.skills.length > 0 ">
            <v-chip v-for="(item, i) in userData.skills" :key="i" class="ma-2" color="#3F44A6" text-color="white" :close="true" @click:close="openUserSkillsWarning(item.id)">
                {{ item.skill_name }}
            </v-chip>
        </v-chip-group>
        <v-sheet v-show="isLoading" :color="`grey lighten-4`" class="p-2">
            <v-skeleton-loader class="d-flex " type="chip@3"></v-skeleton-loader>
        </v-sheet>

        <h3 v-show="isLoading === false && this.userData.skills.length === 0" class="text-center mb-10">{{ $t("userNoSkills") }}</h3>
    </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import AddSkillsToUserAdminDialog from '@/components/socialvue/dialogs/Admin/AddSkillsToUserAdminDialog';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import axios from 'axios';

export default {
    name: 'UserInfoSkillsPanel',
    components: {
        AddSkillsToUserAdminDialog,
        DeleteWarning
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        fetchUser: {
            type: Function,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            showSkillsDialog: false,
            showUserSkillsDelete: false,
            loadingUserSkills: false,
            selectedUserSkills: null
        };
    },
    methods: {
        openSkillsDialog () {
            this.showSkillsDialog = true;
        },
        closeSkillsDialog () {
            this.showSkillsDialog = false;
        },
        onDeleteUserSkillsClose () {
            this.selectedSkillsIdToDelete = [];
            this.showUserSkillsDelete = false;
        },
        openUserSkillsWarning (item) {
            this.showUserSkillsDelete = true;
            this.selectedSkillsIdToDelete = [item];
        },
        deleteUserSkills () {
            const userId = this.$route.params.id;
            const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/skills/delete`;
            axios
                .delete(url, {
                    data: {
                        user_id: userId,
                        skill_ids: this.selectedSkillsIdToDelete
                    }
                })
                .then(() => {
                    this.loadingUserSkills = false;
                    this.selectedSkillsIdToDelete = [];
                    this.fetchUser();
                })
                .catch((error) => {
                    this.loadingUserSkills = false;
                    this.selectedSkillsIdToDelete = [];
                    this.showError(error);
                });
            this.onDeleteUserSkillsClose();
        }
    }
}
</script>

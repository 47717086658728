<template>
<v-autocomplete
            :items="skills"
            v-model="skillsSelected"
            color="#59D79D"
            item-color="#59D79D"
            item-text="name"
            item-value="id"
            chips
            multiple
            solo
            :search-input.sync="searchInput"
            @change="searchInput = ''"
            :loading="isLoading"
            :disabled="disabled"
            @input="$emit('onSkillsSelectedChange', skillsSelected)"
            :rules="rules"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.skill_name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              <v-chip color="#59D79D" class="white--text">{{item?.skill_name}}</v-chip>
            </template>
          </v-autocomplete>
</template>
<script>
import axios from 'axios';
import _debounce from 'lodash/debounce';

export default {
    name: 'SkillsAutoComplete',
    props: ['disabled', 'userSkillsIds', 'rules'],
    data () {
        return {
            skills: [],
            searchInput: '',
            isLoading: false,
            loading: false,
            skillsSelected: this.userSkillsIds || []
        };
    },
    methods: {
        getCategorySubcategoryName (item) {
            return item?.category_name + ' - ' + item?.name;
        },
        getSkillsData: _debounce(function () {
        this.isLoading = true;
        const url = (process.env.VUE_APP_BASE_URL + `/api/skills?search=${this.searchInput === null
          ? '' : this.searchInput}&page=1`).trim();
        axios.get(url).then((response) => {
          this.skills = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.isLoading = false;
        });
    }, 500)
    },
    created () {
    this.getSkillsData();
  }
}
</script>

<template>
<v-form ref="form" lazy-validation @submit.prevent="submitForm">
    <SkillsAutoComplete :userSkillsIds="formValues.skill_ids" @onSkillsSelectedChange="updateSkillsIds" :disabled="isLoading" :rules="SkillsRules"/>
    <v-spacer></v-spacer>
    <slot name="actionArea" :submitForm="submitForm" :resetForm="resetForm"></slot>

    <save-button :save="submitForm" :loading="isLoading" :message="$t('save')" v-show="!hasActionSlot()" />
</v-form>
</template>

<script>
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import SkillsAutoComplete from '@/components/socialvue/autocompletes/SkillsAutoComplete.vue';
export default {
    name: 'AddEditUserSkillForm',
    props: ['onSubmit', 'userSkillsIds', 'onSubmitSuccess', 'isLoading'],
    components: {
        SaveButton,
        SkillsAutoComplete
    },
    data () {
        return {
            formValues: {
                skill_ids: this.userSkillsIds || []
            },
            SkillsRules: [
                v => v.length > 0 || this.$t('fieldSkillRequired')
            ]
        }
    },
    methods: {
        async submitForm () {
            if (this.isLoading) {
                return;
            }
            if (this.$refs.form.validate()) {
                this.onSubmit({
                    ...this.formValues
                });
            }
        },
        hasActionSlot () {
            return !!this.$scopedSlots.actionArea
        },
        updateSkillsIds (skillsIds) {
            this.formValues.skill_ids = skillsIds;
        },
        resetForm () {
            this.$refs.form.reset();
        }
    }
};
</script>

<template>
<v-dialog v-model="showDialog" width="500" persistent @keydown.esc="onClose">
    <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary white--text mb-10">
            <span class="headline">
                {{ userSkillsIds.length > 0 ? $t("editSkill") : $t("AddSkillToUser") }}
            </span>
        </v-card-title>
        <v-card-text>
            <AddEditUserSkillForm :userSkillsIds="userSkillsIds" :on-submit="submitSkills" :user-id="userId" :isLoading="submitSkillsIsLoading">
                <template v-slot:actionArea="slotProps">
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <cancel-button :cancel="onClose" :message="$t('cancel')" :disabled="submitSkillsIsLoading" />
                        <save-button :save="slotProps.submitForm" :loading="submitSkillsIsLoading" :message="$t('save')" />
                    </v-card-actions>
                </template>
            </AddEditUserSkillForm>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import AddEditUserSkillForm from '@/components/socialvue/forms/AddEditUserSkillForm';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
export default {
    name: 'AddSkillsToUserAdminDialog',
    props: ['showDialog', 'userId', 'onClose', 'refreshDataFn', 'userSkillsIds'],
    mixins: [errorDialog],
    components: {
        AddEditUserSkillForm,
        SaveButton,
        CancelButton
    },
    data () {
        return {
            submitSkillsIsLoading: false
        }
    },
    methods: {
        async submitSkills (skillsIdsObj) {
            this.submitSkillsIsLoading = true;
            const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.userId}/skills`;
            axios
                .patch(url, {
                    ...skillsIdsObj,
                    user_id: this.userId
                }).then(() => {
                    this.submitSkillsIsLoading = false;
                    this.onUpdateData()
                }).catch((error) => {
                    this.submitSkillsIsLoading = false;
                    this.showError(error);
                });
        },
        onUpdateData () {
            this.refreshDataFn();
            this.onClose();
        }
    }

};
</script>

<template>
<v-dialog v-model="showDialog" width="500" persistent @keydown.esc="onClose">
    <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary white--text mb-10">
            <span class="headline">
                {{ userCategoriesIds.length > 0 ? $t("edit Category") : $t("createCategory") }}
            </span>
        </v-card-title>
        <v-card-text>
            <AddEditUserCategoryForm :userCategoriesIds="userCategoriesIds" :on-submit="submitCategories" :user-id="userId" :isLoading="submitCategoriesIsLoading">
                <template v-slot:actionArea="slotProps">
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <cancel-button :cancel="onClose" :message="$t('cancel')" :disabled="submitCategoriesIsLoading" />
                        <save-button :save="slotProps.submitForm" :loading="submitCategoriesIsLoading" :message="$t('save')" />
                    </v-card-actions>
                </template>
            </AddEditUserCategoryForm>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import AddEditUserCategoryForm from '@/components/socialvue/forms/AddEditUserCategoryForm';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
    name: 'AddCategoryToUserAdminDialog',
    mixins: [errorDialog],
    props: ['showDialog', 'userId', 'onClose', 'refreshDataFn', 'userCategoriesIds'],
    components: {
        AddEditUserCategoryForm,
        SaveButton,
        CancelButton
    },
    data () {
        return {
            submitCategoriesIsLoading: false
        }
    },
    methods: {
        async submitCategories (categoriesIdsObj) {
            this.submitCategoriesIsLoading = true;
            const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.userId}/categories`;
            axios
                .patch(url, {
                    ...categoriesIdsObj,
                    user_id: this.userId
                }).then(() => {
                    this.submitCategoriesIsLoading = false;
                    this.onUpdateData()
                }).catch((error) => {
                    this.submitCategoriesIsLoading = false;
                    this.showError(error);
                });
        },
        onUpdateData () {
            this.refreshDataFn();
            this.onClose();
        }
    }
};
</script>

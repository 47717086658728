<template>
    <v-form ref="form" lazy-validation @submit.prevent="onSubmit">
        <v-text-field :label="$t('domicile')" v-model="formValues.line_1" color="#59D79D" :rules="domicileRules" :disabled="isLoading">
        </v-text-field>
        <v-text-field :label="$t('postalCode')" v-model="formValues.postal_code" color="#59D79D" type="number" :rules="postalCodeRules" :disabled="isLoading">
        </v-text-field>
        <v-select
          :label="$t('city')"
          v-model="formValues.city"
          v-if="!$options.location.includes('br')"
          color="#59D79D"
          :items="cities"
          :rules="cityRules"
          :disabled="isLoading"
        ></v-select>
        <v-text-field
          v-if="$options.location.includes('br')"
          :label="$t('city')"
          v-model="city"
          color="#59D79D"
          :items="formValues.city"
          :rules="cityRules"
          :disabled="isLoading"
        ></v-text-field>
        <v-text-field :label="$t('state')" v-model="formValues.state" color="#59D79D" :rules="stateRules" :disabled="isLoading">
        </v-text-field>
        <v-checkbox v-model="formValues.is_default" :label="$t('isDefault')" color="#59D79D" :disabled="isLoading">
        </v-checkbox>
        <v-spacer></v-spacer>
        <slot name="actionArea" :submitForm="submitForm"></slot>
        <save-button :save="submitForm" :loading="isLoading" :message="$t('save')" v-show="!hasActionSlot()" />
    </v-form>
</template>

<script>
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
export default {
    name: 'AddEditUserAddressesForm',
    props: ['onSubmit', 'userAddress', 'isLoading'],
    components: {
        SaveButton
    },
    data () {
        return {
            domicileRules: [
                v => !!v || this.$t('fieldDomicileRequired')
            ],
            postalCodeRules: [
                v => !!v || this.$t('fieldPostalCodeRequired')
            ],
            cityRules: [
                v => !!v || this.$t('fieldCityRequired')
            ],
            stateRules: [
                v => !!v || this.$t('fieldStateRequired')
            ],
            formValues: {
                line_1: null,
                postal_code: null,
                city: null,
                state: null,
                is_default: false
            },
            cities: [
                'Artigas',
                'Canelones',
                'Cerro Largo',
                'Colonia',
                'Durazno',
                'Flores',
                'Florida',
                'Lavalleja',
                'Maldonado',
                'Montevideo',
                'Paysandú',
                'Río Negro',
                'Rivera',
                'Rocha',
                'Salto',
                'San José',
                'Soriano',
                'Tacuarembó',
                'Treinta y Tres'
            ]
        }
    },
    methods: {
        async submitForm () {
            if (this.isLoading) {
                return;
            }
            if (this.$refs.form.validate()) {
                this.onSubmit({
                    ...this.formValues
                });
            }
        },
        hasActionSlot () {
            return !!this.$scopedSlots.actionArea
        },
        setImportedConstants () {
            this.$options.location = process.env.VUE_APP_LOCATION;
        }
    },
    created () {
        this.setImportedConstants();
        if (this.userAddress) {
            this.formValues.postal_code = this.userAddress.postal_code;
            this.formValues.city = this.userAddress.city;
            this.formValues.country = this.userAddress.country;
            this.formValues.state = this.userAddress.state;
            this.formValues.line_1 = this.userAddress.line_1;
            this.formValues.is_default = this.userAddress.is_default;
        }
    }
};
</script>

<template>
        <v-card class="rounded-xl">
            <delete-warning
            v-show="showConfirmDelete"
            :confirm-delete="()=>onDelete(address.id)"
            :on-close="onClose"
            :show-delete="showUserCategoryDelete"
            :loading="false"
            />
            <v-card-title>
            <strong>{{ $t("domicile") }}: {{ address.line_1 }}</strong>
            </v-card-title>
            <v-card-text class="py-0">
            <span>{{ $t("postalCode") }}: {{ address.postal_code }}</span>
            <br />
            <span>{{ $t("country") }}: {{ address.country }}</span>
            <br />
            <span>{{ $t("city") }}: {{ address.city }}</span>
            <br />
            <span>{{ $t("state") }}: {{ address.state }}</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pt-0">
            <v-chip class="green-prosk bg-prosk-secondary font-weight-bold" v-if="address.is_default">
                {{ $t("isDefault") }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn icon color="red" @click="showConfirmDelete ? openDeleteConfirmation() : onDelete(address.id)">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="onEdit(address)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
  </template>

<script>
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';

export default {
  components: { DeleteWarning },
  name: 'UserAddressCard',
  props: ['address', 'onEdit', 'onDelete', 'showConfirmDelete'],
  data () {
    return {
        showUserCategoryDelete: false
    }
  },
  methods: {
    openDeleteConfirmation () {
      this.showUserCategoryDelete = true;
    },
    onClose () {
      this.showUserCategoryDelete = false;
    }
  }
};
</script>

<template>
  <v-expansion-panel>
      <v-expansion-panel-header>
          {{ $t('subscriptionPlan') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <AddEditUserPlanForm :user="userData" />
      </v-expansion-panel-content>
  </v-expansion-panel>
</template>

  <script>
  import axios from 'axios';
  import AddEditUserPlanForm from '@/components/socialvue/forms/AddEditUserPlanForm';
  export default {
      name: 'UserPlan',
      components: {
        AddEditUserPlanForm
      },
      props: {
          userData: {
              type: Object,
              required: true
          },
          fetchUser: {
              type: Function,
              required: true
          },
          isLoading: {
              type: Boolean,
              required: true
          }
      },
      data () {
          return {
              userFormIsSubmitting: false
          };
      },
      methods: {
          submitPersonalInfos (data) {
              const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.userData.id}`;
              this.userFormIsSubmitting = true;
              axios.put(url, data).then(() => {
                  this.userFormIsSubmitting = false;
              }).then(() => {
                  this.userFormIsSubmitting = false;
                  this.fetchUser();
              }).catch((error) => {
                  this.userFormIsSubmitting = false;
                  this.showError(error);
              })
          }
      }
  };
</script>

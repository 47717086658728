<template>
<v-form ref="form" lazy-validation @submit.prevent="submitForm">
    <CategoriesAutoComplete :rules="CategoryRules" :userCategoriesIds="formValues.category_ids" @onCategoriesSelectedChange="updateCategoriesIds" :disabled="isLoading" />
    <v-spacer></v-spacer>
    <slot name="actionArea" :submitForm="submitForm" :resetForm="resetForm"></slot>

    <save-button :save="submitForm" :loading="isLoading" :message="$t('save')" v-show="!hasActionSlot()" />
</v-form>
</template>

<script>
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CategoriesAutoComplete from '@/components/socialvue/autocompletes/CategoriesAutoComplete.vue';
export default {
    name: 'AddEditUserCategoryForm',
    props: ['onSubmit', 'userCategoriesIds', 'isLoading'],
    components: {
        SaveButton,
        CategoriesAutoComplete
    },
    data () {
        return {
            formValues: {
                category_ids: this.userCategoriesIds || []
            },
            CategoryRules: [
                v => v.length > 0 || this.$t('fieldCategoryRequired')
            ]
        }
    },
    methods: {
        async submitForm () {
            if (this.isLoading) {
                return;
            }
            if (this.$refs.form.validate()) {
                this.onSubmit({
                    ...this.formValues
                });
            }
        },
        hasActionSlot () {
            return !!this.$scopedSlots.actionArea
        },
        updateCategoriesIds (categoriesIds) {
            this.formValues.category_ids = categoriesIds;
        },
        resetForm () {
            this.$refs.form.reset();
        }
    },
    created () {
        if (this.userAddress) {
            this.formValues.postal_code = this.userAddress.postal_code;
            this.formValues.city = this.userAddress.city;
            this.formValues.country = this.userAddress.country;
            this.formValues.state = this.userAddress.state;
            this.formValues.line_1 = this.userAddress.line_1;
            this.formValues.is_default = this.userAddress.is_default;
        }
    }
};
</script>

<template>
<v-expansion-panel>
    <v-expansion-panel-header>
        {{ $t("domicile") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
        <v-row>
            <v-col col="12" class="d-flex justify-end">
                <v-btn @click="openUserAddressDialogOnCreate" color="bg-green-prosk" small class="white--text">
                    {{ $t("createAddress") }}
                </v-btn>
            </v-col>
        </v-row>
        <UserAddressAdminDialog :key="showAddressDialog" :showDialog="showAddressDialog" :userAddress="selectedAddress" :userId="userData.id" :onClose="closeUserAddressDialog" :refreshDataFn="fetchUser" />
        <h3 v-show="isLoading===false && this.userData.addresses.length===0" class="text-center mb-10">{{ $t("noAddresses") }}</h3>
        <v-row>
            <v-col cols="12" md="4" lg="3" v-for="(address, i) in this.userData.addresses" :key="i">
                <UserAddressCard v-if="isLoading===false" :address="address" :onEdit="openUserAddressDialogOnEdit" :onDelete="onDeleteAddress" :showConfirmDelete="true" />
                <v-sheet v-else :color="`grey lighten-4`" class="pa-3">
                    <v-skeleton-loader type="article, actions"></v-skeleton-loader>
                </v-sheet>
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import axios from 'axios';
import UserAddressAdminDialog from '@/components/socialvue/dialogs/Admin/UserAddressAdminDialog';
import UserAddressCard from '@/components/socialvue/cards/UserAddressCard';

export default {
    name: 'UserInfoAddressPanel',
    components: {
        UserAddressCard,
        UserAddressAdminDialog
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        fetchUser: {
            type: Function,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            showAddressDialog: false,
            selectedAddress: null
        };
    },
    methods: {
        openUserAddressDialogOnCreate () {
            this.selectedAddress = null;
            this.showAddressDialog = true;
        },
        openUserAddressDialogOnEdit (address) {
            this.selectedAddress = address;
            this.showAddressDialog = true;
        },
        closeUserAddressDialog () {
            this.showAddressDialog = false;
        },
        onDeleteAddress (id) {
            axios.delete(`${process.env.VUE_APP_BASE_URL}/api/addresses/${id}`)
                .then(() => {
                    this.fetchUser();
                    this.closeUserAddressDialog();
                });
        }
    }
};
</script>

<template>
  <div>
    <v-col cols="12" class="p-0">
      <v-text-field
        :label="$t('subscriptionPlan')"
        v-model="subscriptionPlan"
        color="#59D79D"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="p-0" v-show="expirationDate">
      <v-text-field
        :label="$t('expirationDate')"
        v-model="expirationDate"
        color="#59D79D"
        disabled
      ></v-text-field>
      <v-date-picker
        v-model="expirationDate"
        :label="$t('expirationDate')"
        @input="menu = false"
        :locale="($i18n.locale === 'pt-BR' || $i18n.locale === 'br') ? 'pt' : $i18n.locale"
        disabled
      ></v-date-picker>
    </v-col>
    <v-col v-show="!expirationDate">
      <v-text-field
        :label="$t('noExpirationDate')"
        v-model="expirationDate"
        color="#59D79D"
        disabled
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-text-field
        :label="$t('planPrice')"
        v-model="planPrice"
        color="#59D79D"
        disabled
      ></v-text-field>
    </v-col>
  </div>
</template>

<script>
  export default {
      name: 'AddEditUserPlanForm',
      props: ['user'],
      data () {
          return {
            subscriptionPlan: '',
            expirationDate: '',
            planPrice: ''
          }
      },
      created () {
        const lastSubscription = this.user.active_subscription[this.user.active_subscription.length - 1];

        this.subscriptionPlan = lastSubscription.name
        this.expirationDate = lastSubscription.expirationDate;
        this.planPrice = lastSubscription.cost;
      }
  };
  </script>

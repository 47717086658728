<template>
<v-expansion-panel>
    <delete-warning :confirm-delete="deleteUserCategory" :on-close="onDeleteUserCategoryClose" :show-delete="showUserCategoryDelete" :loading="loadingDeleteUserCategory" />
    <v-expansion-panel-header>
        {{ $t("categories") }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
        <v-row>
            <v-col col="12" class="d-flex justify-end">
                <v-btn @click="openCategoryDialog" color="bg-green-prosk" small class="white--text">
                    {{ !userData.categories || userData.categories.length > 0 ? $t("edit Category") : $t("createCategory") }}
                </v-btn>
            </v-col>
        </v-row>
        <AddCategoryToUserAdminDialog :key="showCategoryDialog" :showDialog="showCategoryDialog" :userId="userData.id" :onClose="closeCategoryDialog" :refreshDataFn="fetchUser" :userCategoriesIds="userData.categories ? userData.categories.map(v=>v.id) : []" />
        <v-chip-group column v-show="isLoading === false  && userData.categories.length > 0">
            <v-chip v-for="(item, i) in userData.categories" :key="i" class="ma-2" color="#3F44A6" text-color="white" :close="true" @click:close="openUserCategoryWarning(item.id)">
                {{ item.name }}
            </v-chip>
        </v-chip-group>
        <v-sheet v-show="isLoading" :color="`grey lighten-4`" class="p-2">
            <v-skeleton-loader class="d-flex " type="chip@3"></v-skeleton-loader>
        </v-sheet>
        <h3 v-show="isLoading===false && userData.categories.length === 0" class="text-center mb-10">{{ $t("userNoCategories") }}</h3>
    </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import AddCategoryToUserAdminDialog from '@/components/socialvue/dialogs/Admin/AddCategoryToUserAdminDialog';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import axios from 'axios';
export default {
    name: 'UserInfoCategoriesPanel',
    components: {
        AddCategoryToUserAdminDialog,
        DeleteWarning
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
        fetchUser: {
            type: Function,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            showCategoryDialog: false,
            selectedCategory: null,
            showUserCategoryDelete: false,
            selectedCategoryIdToDelete: [],
            loadingDeleteUserCategory: false
        }
    },
    methods: {
        deleteUserCategory () {
            const userId = this.$route.params.id;
            const url = `${process.env.VUE_APP_BASE_URL}/api/users/${userId}/categories/delete`;
            this.loadingDeleteUserCategory = true;
            axios
                .delete(url, {
                    data: {
                        user_id: userId,
                        category_ids: this.selectedCategoryIdToDelete
                    }
                })
                .then(() => {
                    this.loadingDeleteUserCategory = false;
                    this.selectedCategoryIdToDelete = [];
                    this.fetchUser();
                })
                .catch((error) => {
                    this.loadingDeleteUserCategory = false;
                    this.selectedCategoryIdToDelete = [];
                    this.showError(error);
                });
            this.onDeleteUserCategoryClose();
        },
        onDeleteUserCategoryClose () {
            this.selectedCategoryIdToDelete = [];
            this.showUserCategoryDelete = false;
        },
        openUserCategoryWarning (item) {
            this.showUserCategoryDelete = true;
            this.selectedCategoryIdToDelete = [item];
        },
        openCategoryDialog () {
            this.showCategoryDialog = true;
        },
        closeCategoryDialog () {
            this.showCategoryDialog = false;
        }
    }
}
</script>

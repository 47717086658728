<template>
<v-dialog v-model="showDialog" width="500" persistent @keydown.esc="onClose">
    <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary white--text">
            <span class="headline">
                {{ !!userAddress ? $t("editAddress") : $t("createAddress") }}
            </span>
        </v-card-title>
        <v-card-text>
            <AddEditUserAddressesForm :on-submit="submitAddress" :user-address="userAddress" :user-id="userId">
                <template v-slot:actionArea="slotProps">
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <cancel-button :cancel="onClose" :message="$t('cancel')" :disabled="submitAddressIsLoading" />
                        <save-button :save="slotProps.submitForm" :loading="submitAddressIsLoading" :message="$t('save')" />
                    </v-card-actions>
                </template>
            </AddEditUserAddressesForm>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import AddEditUserAddressesForm from '@/components/socialvue/forms/AddEditUserAddressesForm';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import axios from 'axios';

export default {
    name: 'UserAddressAdminDialog',
    props: ['showDialog', 'userAddress', 'userId', 'onClose', 'refreshDataFn'],
    mixins: [errorDialog],
    components: {
        AddEditUserAddressesForm,
        SaveButton,
        CancelButton
    },
    data () {
        return {
            submitAddressIsLoading: false
        }
    },
    methods: {
        async submitAddress (address) {
            this.submitAddressIsLoading = true;
            if (this.userAddress) {
                const urlEdit = `${process.env.VUE_APP_BASE_URL}/api/addresses/`;
                axios
                    .put(urlEdit + this.userAddress.id, address).then(() => {
                        this.submitAddressIsLoading = false;
                        this.onUpdateData()
                    }).catch((error) => {
                        this.submitAddressIsLoading = false;
                        this.showError(error);
                    });
            } else {
                const url = `${process.env.VUE_APP_BASE_URL}/api/addresses`;
                axios
                    .post(url, {
                        ...address,
                        user_id: this.userId
                    }).then(() => {
                        this.submitAddressIsLoading = false;
                        this.onUpdateData()
                    }).catch((error) => {
                        this.submitAddressIsLoading = false;
                        this.showError(error);
                    });
            }
        },
        onUpdateData () {
            this.refreshDataFn();
            this.onClose();
        }
    }

};
</script>
